import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';

const StyledBullet = styled.div.withConfig({
  componentId: "sg16120__sc-9zppyi-0"
})(["align-items:center;display:flex;"]);
const getColor = color => {
  if (!color || color === 'warning') {
    return tokens.color['neutral-9'];
  }
  return tokens.color.white;
};
const StyledBulletContent = styled.div.withConfig({
  componentId: "sg16120__sc-9zppyi-1"
})(["align-items:center;background-color:", ";border-radius:", ";color:", ";display:flex;flex:0 0 auto;justify-content:center;position:relative;transition:box-shadow ", ",background-color ", ";height:", ";width:", ";", " ", " ", "   ", ""], props => tokens.color[props.$color || 'neutral-3'], tokens.radius.circle, props => getColor(props.$color), tokens.transition.fast, tokens.transition.fast, tokens.space.xl, tokens.space.xl, props => props.$variant === 'outline' && css(["background-color:", ";border:1px solid ", ";color:", ";"], tokens.color.white, tokens.color[props.$color || 'neutral-5'], tokens.color[props.$color || 'neutral-5']), props => props.$variant === 'ghost' && css(["background-color:transparent;border:1px solid ", ";color:", ";"], tokens.color[props.$color || 'neutral-5'], tokens.color[props.$color || 'neutral-5']), props => props.$size && css(["font-size:", ";height:", ";width:", ";"], props.$size === 'xxxl' || props.$size === 'layout-lg' ? tokens['font-size'].xl : undefined, tokens.space[props.$size], tokens.space[props.$size]), props => props.$shape === 'square' && css(["border-radius:", ";"], tokens.radius.md));
const StyledBulletBadgeIconContainer = styled.div.withConfig({
  componentId: "sg16120__sc-9zppyi-2"
})(["align-items:center;background-color:", ";border-radius:", ";display:flex;height:1.5rem;justify-content:center;padding:0.25rem;position:absolute;right:-0.25rem;top:0;width:1.5rem;", ""], tokens.color['neutral-5'], tokens.radius.circle, props => props.$color && css(["background-color:", ";color:", ";"], tokens.color[props.$color], tokens.color.white));

export { StyledBullet, StyledBulletBadgeIconContainer, StyledBulletContent };
