import styled, { css } from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Spacer } from '../Spacer/Spacer.js';
import { Button } from '../Button/Button.js';
import { Divider } from '../Divider/Divider.js';
import { media } from '../utils/styles/media.js';

const StyledLabel = styled(Spacer).withConfig({
  componentId: "sg16120__sc-1xkeso8-0"
})(["color:", ";position:relative;"], props => props.$disabled ? tokens.color['neutral-6'] : undefined);
const StyledButton = styled.button.withConfig({
  componentId: "sg16120__sc-1xkeso8-1"
})(["background:transparent;border:0;border-radius:", ";color:", ";display:block;max-width:100%;padding:0;position:", ";text-align:left;transition:box-shadow ", ";width:", ";&:not(:disabled){cursor:pointer;}&:disabled{color:", ";}&:focus{box-shadow:", ";outline:none;}&:hover:not(:disabled){color:", ";}", ""], tokens.radius.md, tokens.color.primary, props => props.$spread ? undefined : 'relative', tokens.transition.fast, props => props.$spread ? '100%' : undefined, tokens.color['neutral-6'], props => !props.$spread && tokens.shadow.focus, tokens.color['primary-light'], props => props.$spread && css(["&::after{border-radius:", ";bottom:-1px;content:'';left:-1px;position:absolute;right:-1px;top:-1px;transition:box-shadow ", ";z-index:1;}&:focus::after{box-shadow:", ";}"], tokens.radius.md, tokens.transition.fast, tokens.shadow.focus));
const StyledToggleButton = styled(Button).withConfig({
  componentId: "sg16120__sc-1xkeso8-2"
})(["&&{display:block;:focus:not(:focus-visible){box-shadow:none;}}"]);
const StyledDivider = styled(Divider).withConfig({
  componentId: "sg16120__sc-1xkeso8-3"
})(["padding-top:1rem;", "{padding-top:2rem;}"], media.md);

export { StyledButton, StyledDivider, StyledLabel, StyledToggleButton };
